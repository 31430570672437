import React from "react";
import { graphql } from "gatsby";
import * as TermHtml from "../../Legal/mobile/termHtml";
import MobileLayout from "../../components/Mobile/layout/index";

const Terms = () => {
  return (
    <MobileLayout>
      <div
        style={{
          padding: "2em 1em",
          fontSize: "1em",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: 960,
            margin: "0 auto",
            width: "100%",
          }}
          dangerouslySetInnerHTML={{
            __html: TermHtml["EN"].html,
          }}
        />
      </div>
    </MobileLayout>
  );
};
export default Terms;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
