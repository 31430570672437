export const EN = {
  html: `
<div>
<p class="p1"><b>TERMS AND CONDITIONS</b></p>
<p class="p1"><b>KUU CREATOR <br>
KUNANA COMPANY LIMITED</b></p>
<p class="p2"><br></p>
<p class="p3"><b>[Version: 1]</b></p>
<p class="p3"><b>[14 Oct 2021]</b><span class="Apple-converted-space"> <span class="Apple-tab-span">	</span></span></p>
<p class="p3">These terms and conditions (“<b>Terms and Conditions</b>”) represent an agreement between the users (“<b>User</b>”) and Kunana Company Limited (“<b>Company</b>”), governing the following terms, conditions and obligations of each party in relation to the use of the Company’s website (<b>“Website”) </b>and application (<b>“App”) </b>(collectively called the “<b>Platform</b>”).</p>
<p class="p3">Please read the Terms and Conditions carefully, before using the Company’s Platform.</p>
<p class="p4"><br></p>
<p class="p3"><b>1.<span class="Apple-tab-span">	</span>General Provisions</b></p>
<p class="p5">1.1<span class="Apple-tab-span">	</span>Please carefully read the following terms and conditions.<span class="Apple-converted-space">  </span>If you do not agree with these Terms and Conditions, or you do not wish to be bound by the Terms and Conditions, please do not use our Services and leave the Platform immediately.</p>
<p class="p5">1.2<span class="Apple-tab-span">	</span>By registering or creating the account, including but not limited to browsing the Platform, downloading and uploading content and using website links, it is assumed that you have understood, accepted and agreed to be bound by, and will strictly comply with, all the terms and conditions stated herein and other policies of the Company, as well as relevant policies, laws, regulations, notifications or rules, without reservation.</p>
<p class="p5">1.3<span class="Apple-tab-span">	</span>The User may use the Services only if the User is able to enter into an agreement with the Company legally, and only in compliance with these Terms and Conditions, as well as all applicable local and international laws, rules and regulations. If you are under 20 years of age or not <i>sui juris</i> under the applicable law, you must be represented by your parents or guardians in order to agree to these Terms and Conditions.</p>
<p class="p6"><br></p>
<p class="p3"><b>2.<span class="Apple-tab-span">	</span>Definitions</b></p>
<p class="p5">2.1<span class="Apple-tab-span">	</span>“<b>Platform</b>” means the website and application, which are operated/invented, or which will be operated/invented by the Company.</p>
<p class="p5">2.2<span class="Apple-tab-span">	</span>“<b>Services</b>” means the services provided by the Company though the Platform, as specified in the scope of services herein.</p>
<p class="p5">2.3<span class="Apple-tab-span">	</span>“<b>Content</b>” means cutouts, layouts, artwork, icons, vectors, elements, designed texts, templates, photographs, graphics, footage, animation, or any other types of content available on our Platform.</p>
<p class="p5">2.4<span class="Apple-tab-span">	</span>“<b>Company Content</b>” means: (i) the Content which is provided to the User by the Company via the Company’s Platform; and (ii) the User Content which is available in the Content Stock.</p>
<p class="p5">2.5<span class="Apple-tab-span">	</span><b>“User Content”</b> means the Content which is created, developed and/or uploaded by the Users through the Company’s Platform.</p>
<p class="p5">2.6<span class="Apple-tab-span">	</span>“<b>Intellectual Property Rights</b>” means all copyrights and related rights, patents, rights to inventions, utility models, trademarks, service marks, trade, business and domain names, rights in trade dress or get-up, rights in computer software, database rights, semi-conductor topography rights, moral rights, rights in confidential information (including know-how and trade secrets) and any other intellectual property rights; in each case whether registered or unregistered and including all applications for and renewals or extensions of such rights, and all similar or equivalent rights or forms of protection in any part of the world. Intellectual Property Rights means any one of the Intellectual Property Rights.</p>
<p class="p2"><br></p>
<p class="p3"><b>3.<span class="Apple-tab-span">	</span>Scope of Services</b></p>
<p class="p5">3.1<span class="Apple-tab-span">	</span>The Company is the service provider for the Platform, which aims to provide online creative spaces for the User to create, design and invent their Content for their own purposes, as well as provide the Company Content for the User to use when creating their own work.</p>
<p class="p5"><span class="Apple-tab-span">	</span>In providing the Services, there may be a stock which contains the Contents provided by the Company or uploaded or shared by the User for other Users to use, develop, or adapt to their Content, upon the consent of the relevant user (“<b>Content Stock</b>”).</p>
<p class="p6"><br></p>
<p class="p5"><b>4.<span class="Apple-tab-span">	</span>Your Use of Our Services</b></p>
<p class="p5">4.1<span class="Apple-tab-span">	</span>You understand and agree that the Content which is created, shared and/or uploaded by you must not infringe any Intellectual Property Right of any person. You also agree that the Company is not responsible for any liabilities arising from the use of our Services.</p>
<p class="p5">4.2<span class="Apple-tab-span">	</span>In using our Services, you are not allowed to use the Platform and the Content available for the following reason:</p>
<p class="p7">a.<span class="Apple-tab-span">	</span>For any unlawful, obscene or immoral purpose;<span class="Apple-converted-space"> </span></p>
<p class="p7">b.<span class="Apple-tab-span">	</span>To lead or encourage other Users to commit any unlawful acts;</p>
<p class="p8">c.<span class="Apple-tab-span">	</span>To infringe or violate our Intellectual Property Rights, or the Intellectual Property Rights of others;</p>
<p class="p7">d.<span class="Apple-tab-span">	</span>To violate any applicable law;</p>
<p class="p8">e.<span class="Apple-tab-span">	</span>To harass, abuse, insult, harm, defame, slander, disparage, intimidate or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin or disability;<span class="Apple-converted-space"> </span></p>
<p class="p7">f.<span class="Apple-tab-span">	</span>To submit false or misleading information (e.g., fake news or fake information);<span class="Apple-converted-space"> </span></p>
<p class="p8">g.<span class="Apple-tab-span">	</span>To upload or transmit viruses, or any other type of malicious code that will, or which may, be used in any way that will affect the functions or the operation of the Platform, or other websites or applications, regardless of whether or not such websites/applications are linked to the Company’s Platform;</p>
<p class="p8">h.<span class="Apple-tab-span">	</span>To illegally access or track the personal data or other private User Content of other Users;</p>
<p class="p8">i.<span class="Apple-tab-span">	</span>To illegally damage, destroy, correct, change or amend the computer data of the Company and other Users, either in whole or in part;</p>
<p class="p8">j.<span class="Apple-tab-span">	</span>To spam, phish or commit fraud in any way whatsoever, or to conduct any act that may cause the Platform to be suspended, delayed, hindered or disrupted;</p>
<p class="p8">k.<span class="Apple-tab-span">	</span>To cause damage to other people, including but not limited to the Company and the User;</p>
<p class="p7">l.<span class="Apple-tab-span">	</span>To interfere with, or to bypass, the security features of the Company’s Platform;</p>
<p class="p8">m.<span class="Apple-tab-span">	</span>For research and development of artificial intelligence, in particular to the generation of the training data, whereby such research and development may be comparable with or similar to the Services of the Company, whether directly or indirectly.</p>
<p class="p8">n.<span class="Apple-tab-span">	</span>To portray any person, so they are depicted in visual content (a "<b>Model</b>"), in a way that a reasonable person would find offensive, including, but not limited to, depicting a Model: (i) in connection with pornography, "adult videos", adult entertainment venues, escort services, dating services, or the like; (ii) in connection with the advertisement or promotion of tobacco products; (iii) in a political context, such as the promotion, advertisement, or endorsement of any party, candidate, or elected official, or in connection with any political policy or viewpoint; (iv) as suffering from, or medicating for, a physical or mental ailment; or (v) engaging in immoral or criminal activities.</p>
<p class="p8">o.<span class="Apple-tab-span">	</span>To engage in any of the following prohibited activities: (i) copying, distributing, or disclosing any part of the Service in any medium, including without limitation by any automated or non-automated “scraping”; (ii) using any automated system, including without limitation “robots,” “spiders,” “offline readers,” etc., to access the Service in a manner that sends more request messages to the servers than a human can reasonably produce in the same period of time, by using a conventional on-line web browser; (iii) transmitting spam, chain letters, or other unsolicited emails; (iv) attempting to interfere with, compromise the integrity or the security of the system, or decipher any transmissions to or from the servers running the Service; (v) taking any action that imposes, or may impose at our sole discretion, an unreasonable or disproportionately large load on our infrastructure; and (vi) uploading invalid data, viruses, worms, or other software agents through the Services.</p>
<p class="p8">p<span class="Apple-tab-span">	</span>To copy, re-publish, frame, link to, download, transmit, modify, adapt, create derivative works based on, rent, lease, loan, sell, assign, distribute, display, perform, license, sub-license, or reverse engineer, the Company’s Platform and the Company Content.</p>
<p class="p2"><br></p>
<p class="p7">The Company reserves the right to terminate the agreement with you, and to stop the provision of the Services to you immediately, if you violate these prohibitions.</p>
<p class="p9"><br></p>
<p class="p3"><b>5.<span class="Apple-tab-span">	</span>Registration</b></p>
<p class="p5">5.1<span class="Apple-tab-span">	</span>To use the Company’s Platform, you are firstly required to complete the registration. By registering your account, you confirm and certify that you are at least 20 years old, or you have already become <i>sui juris</i> in accordance with the applicable law in your jurisdiction. If you are younger than 20 years old, or not <i>sui juris</i> under the applicable law, you must be represented by your parent(s) or guardian(s), in order to agree to these Terms and Conditions.</p>
<p class="p5">5.2<span class="Apple-tab-span">	</span>To register as a User, you are required to provide complete and accurate information for registration purposes. The Company reserves the right to immediately suspend or terminate your account without prior notice, if you provide any information that is untrue or inaccurate.</p>
<p class="p5">5.3<span class="Apple-tab-span">	</span>You are required to keep your login information, account and password confidential. You must take any necessary measures to prevent unauthorized access by third parties to such information. You also agree and accept that any use of the Platform by a third party through your account is deemed to be your own use, and you will be liable for any consequences and damage which may arise from such access.</p>
<p class="p5">5.4<span class="Apple-tab-span">	</span>The Company may use the contact information you provided during the registration process to communicate with you, as well as to send information, news, promotions and advertisement to you.</p>
<p class="p2"><br></p>
<p class="p3"><b>6.<span class="Apple-tab-span">	</span>Accounts/Price/Subscription Plan</b></p>
<p class="p5">6.1<span class="Apple-tab-span">	</span>The Company may offer a free trial to enable the User to use our Services for a period of time when the User subscribes to a subscription plan offered by the Company, whereby the Company may continue to state additional conditions and requirements for such free trial usage and subscription plan.<span class="Apple-converted-space">  </span>The User may be informed of the free trial in the registration process. Nevertheless, the Company is not obliged to notify the User of the free trial if the User has skipped the enrollment or registration process to use the free trial. <span class="Apple-converted-space"> </span></p>
<p class="p5"><span class="Apple-tab-span">	</span>The terms and conditions of the subscription plans for The App, including the free trial and the in-app purchase, are prescribed in the relevant App Store (Apple App Store or Google Play Store) and constitute a part of these Terms and Conditions.</p>
<p class="p5">6.2<span class="Apple-tab-span">	</span>In the case the User registers to use the free trial, at the end of the free trial, the Services will continue to be provided, and the paid subscription plan will automatically start, whereby the Company will charge the subscription fees for the next billing cycle, unless you cancel the subscription plan before the end of the free trial.</p>
<p class="p5">6.3<span class="Apple-tab-span">	</span>When creating your account on the Platform, you may be asked to provide your payment details in order to subscribe to our Services. By providing such payment details, you agree and provide consent that the Company may charge subscription fees through the payment method which you selected, using the payment details that you provided, as stated in the additional terms and conditions relating to your subscription plan.</p>
<p class="p5">6.4<span class="Apple-tab-span">	</span>Subscriptions allow Users to receive a Product continuously or regularly over a determined period of time. Paid subscriptions begin on the day the payment is received by the Owner. In order to maintain subscriptions, Users must pay the required recurring fee in a timely manner. Failure to do so may cause service interruptions.</p>
<p class="p5">6.5<span class="Apple-tab-span">	</span>App Subscriptions handled via App Store (Apple App Store or Google Play Store)</p>
<p class="p7">Users may subscribe to a Product using their ID associated with their App Store account by using the relevant process on this Application. When doing so, Users acknowledge and accept that any payment due shall be charged to their App store ID account; subscriptions are automatically renewed for the same duration unless the User cancels at least 24 hours before the current period expires; any and all fees or payments due for renewal will be charged within 24-hours before the end of the current period; subscriptions can be managed or cancelled in the Users’ App Store account settings.</p>
<p class="p5">6.6<span class="Apple-converted-space">      </span>The User agrees that the Value Added Tax (VAT) and other applicable taxes related to the current fee shall be paid by the User, and the User hereby agrees to indemnify the Company in the event that there is any relevant expense incurred to the Company.<span class="Apple-converted-space">   </span></p>
<p class="p5">6.7<span class="Apple-tab-span">	</span>The Company may change the account/price/subscription plan from time to time, provided the changes will become effective only after the current billing cycle/payment of your subscription plan. The Company will provide you with reasonable prior notice of any significant change to the account/price/subscription plan, in order to give you an opportunity to cancel your plan before such changes take effect.</p>
<p class="p10"><br></p>
<p class="p3"><b>7.<span class="Apple-tab-span">	</span>Company Content</b></p>
<p class="p5">7.1<span class="Apple-tab-span">	</span>The User agrees that the Company Content and the Intellectual Property Rights related thereto are the exclusive property of the Company or its licensors. The User agrees not to conduct any act, whether directly or indirectly, that may infringe or violate any of such Intellectual Property Rights.</p>
<p class="p5">7.2<span class="Apple-tab-span">	</span>In providing the Services, the Company grants the User a non-exclusive copyright license to download, copy, modify, distribute, develop and use the Company Content for the User’s personal and non-commercial purposes, whereby the User may use the Company Content for advertising purpose, but the User cannot sell such content; provided that it shall not compete with, or otherwise could impair the commercial interest of the Company. This does not include the right to use any of the Company’s trademarks, logos or brands that appear in the Company Content or any personal data appearing in the Company Content.</p>
<p class="p5">7.3<span class="Apple-tab-span">	</span>The User agrees that the use of the Company Content shall be in accordance with the Terms and Conditions, and all applicable laws. Any breach of the Terms and Conditions or the applicable laws can entitle the Company to terminate the Terms and Conditions and the Services immediately without giving advance notice, and shall have a right to claim for compensation for any loss and/or damage which may occur to the Company.</p>
<p class="p5">7.4<span class="Apple-tab-span">	</span>In the event that Company Content is removed from the Content Stock or any other places on the Company’s Platform, the Company may notify the User to suspend from using or to delete such Company Content even though it has been downloaded from the Company’s Platform. The User undertakes that it is the User’s obligation to comply with the request immediately. This restriction will not apply to and affect the User Content consisting of the deleted Company Content, which has been created before the deletion of such Company Content.</p>
<p class="p4"><br></p>
<p class="p3"><b>8.<span class="Apple-tab-span">	</span>User Content</b></p>
<p class="p5">8.1<span class="Apple-tab-span">	</span>The User understands that, in general, the User Content cannot be shared or disclosed to any other persons other than the Company, except for the following circumstances:</p>
<p class="p8">a.<span class="Apple-tab-span">	</span>In the event that you share or upload your User Content to the Content Stock, or you consent for the Company to do so, under which you agree that your User Content available in the Content Stock could be accessed and used by other Users. In this regard, you agree to grant a right to other Users to use or adapt your User Content to their Content for any purpose<span class="Apple-converted-space"> </span></p>
<p class="p8">b.<span class="Apple-tab-span">	</span>In the event that you allow other people to access, develop, modify and create User Content with you via the functions provided by the Company (if any).</p>
<p class="p8">c.<span class="Apple-tab-span">	</span>In the event that you consent for the Company to exhibit your User Content, where your User Content may be seen by other Users, but not allowed to be used or adapted to their User Content.</p>
<p class="p7">In any circumstance, the User agrees that the Company shall not be liable for any loss and damage arising out of the use of your User Content by other Users, unless the loss or damage is incurred by an intention of the Company or its gross negligence.</p>
<p class="p5">8.2<span class="Apple-tab-span">	</span>You guarantee that you own all rights and legally have the full power to use, copy, import, publish, transfer, transmit and share the User Content which you input or upload to the Platform. You also guarantee and agree that you have (and will maintain) full power, title, licenses, consents and authority to grant permission to the Company to access your User Content, for the purpose of importing, copying, displaying, uploading, transmitting and otherwise using your User Content.</p>
<p class="p5">8.3<span class="Apple-tab-span">	</span>You agree that the User Content which you create or upload to the Platform does not contain the following characteristics:</p>
<p class="p11">a.<span class="Apple-tab-span">	</span>May encourage illegal or immoral acts, or lead to a deterioration of local cultures;</p>
<p class="p11">b.<span class="Apple-tab-span">	</span>May create a risk of damage, loss, harm, physical or mental injury, emotional distress, death, disability, disfigurement or physical or mental illness to any person, including but not limited to yourself and other Users, and to animals;<span class="Apple-converted-space"> </span></p>
<p class="p11">c.<span class="Apple-tab-span">	</span>Unlawful, libelous, defamatory, obscene, pornographic, indecent, suggestive, harassing, threatening, invasive of privacy or publicity rights, abusive, offensive, inflammatory or fraudulent;<span class="Apple-converted-space"> </span></p>
<p class="p11">d.<span class="Apple-tab-span">	</span>May constitute, encourage or provide instructions for a criminal offense, or violate the rights of any person, including other Users;</p>
<p class="p11">e.<span class="Apple-tab-span">	</span>May infringe an Intellectual Property Right of any persons;</p>
<p class="p11">f.<span class="Apple-tab-span">	</span>May violate requirements in relation to a regulated business under the applicable law in your jurisdiction, such as the securities business, insurance business or banking business;</p>
<p class="p11">g.<span class="Apple-tab-span">	</span>May cause damage or loss to any person or property;</p>
<p class="p11">h.<span class="Apple-tab-span">	</span>May be deemed to be unlawful, harmful, abusive, racially or ethnically offensive, defamatory, infringing, invasive of personal privacy or publicity rights, harassing, humiliating to other people (publicly or otherwise), libelous, threatening, hostile, violent or which provokes violence or hostility, or profane;</p>
<p class="p11">i.<span class="Apple-tab-span">	</span>May cause harm to children;</p>
<p class="p11">j.<span class="Apple-tab-span">	</span>May case harm to the national security of your country and/or any other country;</p>
<p class="p11">k.<span class="Apple-tab-span">	</span>May lead to discrimination and, in particular, discrimination based on race, religion, sex, sexual orientation, age, disability, ancestry or national origins;</p>
<p class="p11">l.<span class="Apple-tab-span">	</span>Any information or content that you do not have a right to make available under applicable law, and under any contractual obligations;</p>
<p class="p11">m.<span class="Apple-tab-span">	</span>Inaccurate information and content; and</p>
<p class="p11">n.<span class="Apple-tab-span">	</span>Any other information which may create a risk in regard to a violation of the applicable law.</p>
<p class="p5">8.4<span class="Apple-tab-span">	</span>You agree that any User Content does not, and will not, violate third-party rights of any kind, including without limitation Intellectual Property Rights and privacy rights. In the case where the Company becomes aware of any possibility of a violation of the applicable law, in relation to the User Content and the use of the Company’s Services, the Company has the right to take down, remove, delete, suspend, or conduct any other actions to halt the availability of such User Content. In this case, the Company will not be liable for any damages which may be incurred to the User who has created the User Content, or to any other persons.</p>
<p class="p5">8.5<span class="Apple-tab-span">	</span>The Company may use your User Content for research and development of any products and services of the Company, to create new products and services, <br>
to improve your experience in using our products and services</p>
<p class="p5">8.6<span class="Apple-tab-span">	</span>You will be responsible for your User Content and any consequences relating to creating, uploading, importing, sharing or other actions which you conduct in relation to the User Content. You agree that the Company will not be responsible and/or liable for your User Content, as well as any loss and damage which arises from your User Content.</p>
<p class="p5">8.7<span class="Apple-tab-span">	</span>You may jointly create User Content with other Users, upon your request, and extend invitations to other Users, with your approval. In such case, all Users who create the User Content will be jointly responsible and liable for the User Content created.</p>
<p class="p5">8.8 <span class="Apple-tab-span">	</span>You agree that, in the case where your User Content is uploaded to the Content Stock and is used by other Users ( upon the consent of relevant user), if you later delete or amend your User Content but it has been used or downloaded by other Users, the deletion of and/or amendment to your User Content will not affect other Users’ Content, which consists of or is related to your User Content that has been deleted/amended. Furthermore, if your User Content is downloaded by other Users, the Company will not be able - and it is out of the Company’s control and responsibilities - to delete or control the use of such downloaded User Content.</p>
<p class="p5">8.9<span class="Apple-tab-span">	</span>You understand and agree that in the event you delete Content from our Platform, the deletion may possibly not take effect immediately, due to technical limitations/restrictions. The deletion of the Content will however be proceeded as soon as it is possible.</p>
<p class="p5">8.10<span class="Apple-tab-span">	</span>When using the App, you understand and agree that your User Content will be stored in KUU Creator in your working device. We will not be liable for the loss of your content if you delete the App or change to new device.</p>
<p class="p2"><b></b><br></p>
<p class="p3"><b>9.<span class="Apple-tab-span">	</span>Refunds</b></p>
<p class="p7">The User is entitled to cancel the use of the Services, and to delete the Users’ account and User Content at any time. However, regardless of the reasons for the cancellation, the User will not qualify for any refund from the Company. Furthermore, in the case where the Company terminates or ceases your use of our Services under these Terms and Conditions, due to any reason, you agree that no refund will be provided/paid for any unused time or subscription plan, or other payments for which you may be entitled to receive during the use of our Services.</p>
<p class="p2"><br></p>
<p class="p3"><b>10.<span class="Apple-tab-span">	</span>Third-Party Websites or Applications</b></p>
<p class="p7">The Company’s Platform may be linked to third-party websites or applications for the provision of our Services, advertising or any other purposes. In this regard, you understand and agree that your access or use of other linked websites and applications will not be subject to these Terms and Conditions or other policies of the Company; rather, it will be governed by the terms and conditions and policies of these third-party websites/applications, whereby we strongly encourage you to read and understand all terms and conditions. You also agree that the Company is not responsible and liable for damage arising out of the access and use of third-party websites/applications.<span class="Apple-converted-space"> </span></p>
<p class="p9"><br></p>
<p class="p3"><b>11.<span class="Apple-tab-span">	</span>Privacy</b></p>
<p class="p7">You understand and agree that, by using our Platform, you have read, understood, and agree to the Privacy Policy of the Company. You also agree to respect the privacy rights of other Users, and not to violate their privacy at all times during the use of our Platform.</p>
<p class="p2"><b></b><br></p>
<p class="p3"><b>12.<span class="Apple-tab-span">	</span>Amendment to the Content of the Terms and Conditions</b></p>
<p class="p5">12.1<span class="Apple-tab-span">	</span>The Company reserves the sole right to amend, change or add any content or text to these Terms and Conditions (“<b>Amendment</b>”) in separate parts or in whole at any time. Such changes are legitimate and effective from the moment of their publication. Your continued use of the Platform after the Amendment will be deemed to constitute that you acknowledge such Amendment, and that you also agree to be bound by the amended Terms and Conditions<span class="s1">.</span></p>
<p class="p5">12.2<span class="Apple-tab-span">	</span>If you do not wish to use the Platform after such Amendment, please stop using the Platform, and immediately delete your account.</p>
<p class="p2"><br></p>
<p class="p3"><b>13.<span class="Apple-tab-span">	</span>Rights of the Company</b></p>
<p class="p5">13.1<span class="Apple-tab-span">	</span>The Company reserves the right to suspend the use of the Platform at any time, without prior notice, in order to maintain, update, test, develop and/or repair the Platform.</p>
<p class="p5">13.2<span class="Apple-tab-span">	</span>The Company reserves the right to suspend or terminate your account without prior notice in the event the Company has reasonable cause to believe that you have used the Platform inappropriately, or breached the Company’s Terms and Conditions.</p>
<p class="p5">13.3<span class="Apple-tab-span">	</span>The Company reserves the right to increase, reduce, add, remove, change and/or amend any content as it deems appropriate. This is not limited to databases, information, photos, videos, voice, computer programs, downloadable files or content relating to other media which appears on the Platform, or which can be accessed via the Platform.</p>
<p class="p5">13.4<span class="Apple-tab-span">	</span>The Company reserves the right to remove, suspend the use of, and/or transmit or disseminate information through the Platform, in the event that there is illegal conduct or a breach of any regulations, rules, provisions or orders.</p>
<p class="p5">13.5<span class="Apple-tab-span">	</span>The Company reserves the right to change the subscription plan for the use of the Services, including subscription fees or other fees to be determined in the future, whereby the Company may choose to firstly obtain your consent, unless otherwise stated in the law.</p>
<p class="p2"><br></p>
<p class="p3"><b>14.<span class="Apple-tab-span">	</span>Obligations and Liabilities of the User</b></p>
<p class="p5">14.1<span class="Apple-tab-span">	</span>You are obligated to immediately update your information, such as your name, address, contact number, email address or other contact details if there is any change to such information.</p>
<p class="p5">14.2<span class="Apple-tab-span">	</span>You agree and guarantee that your use of the Services, including the creation and uploading of your User Content, will be conducted in accordance with the requirements stated in these Terms and Conditions, and will not be contrary to the applicable law.</p>
<p class="p5">14.3<span class="Apple-tab-span">	</span>You must not transfer or assign any rights or obligations under these Terms and Conditions to a third party, unless written consent is obtained from the Company.</p>
<p class="p2"><br></p>
<p class="p3"><b>15.<span class="Apple-tab-span">	</span>Limitation on Warranty</b></p>
<p class="p5">15.1<span class="Apple-tab-span">	</span>The Company does not make any warranties or representations for any information, products, or services available on the Platform, other than as explicitly stated in these Terms and Conditions, or in any written agreement that you may enter into with the Company, or as required by applicable law.</p>
<p class="p2"><br></p>
<p class="p3"><b>16.<span class="Apple-tab-span">	</span>Limitation of Liability</b></p>
<p class="p5">16.1<span class="Apple-tab-span">	</span>Access to the Platform from various devices depends on several factors, including <br>
the operation system, browser and/or software which is installed on your device. <br>
The Company reserves the right to exclude its liability for any damage or your device’s inability to function normally, which is caused by the installation of the Platform, or any use of the Platform, including computer viruses and malware. In addition, the Company does not represent, nor guarantee, that the Platform is free from computer viruses or malware, or other such things which may affect the functions of your device.</p>
<p class="p5">16.2<span class="Apple-tab-span">	</span>The Company shall not be liable to you and/or any third party for any damage or claims which may arise due to a breach of these Terms and Conditions, which does not result from the intentions or gross negligence of the Company.</p>
<p class="p5">16.3<span class="Apple-tab-span">	</span>The Company shall not be liable for any loss or damage to the information, photos or any content of the Platform which results from a <i>force majeure</i> event, including but not limited to loss or damage resulting from malfunctions of software or devices.</p>
<p class="p5">16.4<span class="Apple-tab-span">	</span>The Company shall not be liable for any errors of the Platform which result from the transmission of data, delays of the system, delays of the device, or delays and any other issues caused by: use of the internet, connection via Bluetooth or Wi-Fi, any electronic communication, errors of your device, the loss of connectivity or inability to function, including the completeness, correctness or how current the information or content available on the Platform is, as well as whether it is caused by technical error or human error.</p>
<p class="p2"><br></p>
<p class="p3"><b>17.<span class="Apple-tab-span">	</span>Indemnification<span class="Apple-converted-space"> </span></b></p>
<p class="p7">You agree and consent to indemnify the Company and its parent company, subsidiaries, affiliated companies, alliances, business partners, licensor, officers, employees, directors and representatives (“Indemnified Party”), from any damages (this shall include reasonable attorneys’ fees) which arose from, or that are in connection with, claims, legal proceedings, demands or filing of complaints by a third party, and which results in the Company being subject to liability in relation to these Terms and Conditions due to: (1) the User’s usage or other actions which are in violation of the law or these Terms and Conditions; or (2) the User performing any other acts intentionally, or with gross negligence, which causes damage to or infringes any rights of such third party.<span class="Apple-converted-space"> </span></p>
<p class="p2"><b></b><br></p>
<p class="p3"><b>18.<span class="Apple-tab-span">	</span>Intellectual Property Rights</b></p>
<p class="p5">18.1<span class="Apple-tab-span">	</span>You agree that, except for your User Content, the Services, information, the Company Content and the Intellectual Property Rights related thereto, are the property of the Company and its licensors (if any). Except as explicitly provided herein, nothing in this Agreement shall be deemed to grant permission or authority in respect to, or under, our Intellectual Property Rights, and you agree not to reproduce, sell, access or use in any manner, any or all of the content or data on the Application, or the Company Content, without obtaining prior explicit consent from the Company.</p>
<p class="p2"><br></p>
<p class="p3"><b>19.<span class="Apple-tab-span">	</span>Termination of the Account</b></p>
<p class="p5">19.1<span class="Apple-tab-span">	</span>Unless otherwise provided at the time of your registration, you may terminate your registered user status at any time by deleting your account. You agree and acknowledge that any benefits which you have received while being a User will be terminated, along with your termination of the user status.</p>
<p class="p5">19.2<span class="Apple-tab-span">	</span>The Company is entitled to immediately terminate your account if it finds that you have violated any or all of the restrictions, or prohibitions prescribed herein, without prior notice.</p>
<p class="p5">19.3<span class="Apple-tab-span">	</span>The company may terminate your account under other circumstances, for which the Company deems to be necessary or appropriate, or when the Company stops providing services for the Platform, or when the Company halts its business operations, without prior notice.</p>
<p class="p2"><br></p>
<p class="p3"><b>20.<span class="Apple-tab-span">	</span>Entire Agreement</b></p>
<p class="p7">These Terms and Conditions constitute the entire agreement between the Company and the User, and supersedes all prior agreements and conditions, texts, or any communication, either verbal or in writing, in relation to the subject matter of these Terms and Conditions (if any).</p>
<p class="p2"><br></p>
<p class="p3"><b>21.<span class="Apple-tab-span">	</span>Severability</b></p>
<p class="p7">If any of the terms or contents contained in these Terms and Conditions are found to be invalid, unlawful or unenforceable, the remaining terms and conditions will continue to be valid and enforceable to the fullest extent permitted by law.</p>
<p class="p2"><br></p>
<p class="p3"><b>22.<span class="Apple-tab-span">	</span>Waiver</b></p>
<p class="p7">The Company’s failure or delay to enforce any rights in these Terms and Conditions, or any single or partial exercise of any such rights by the Company, shall not preclude any other, or further exercise of, such rights under these Terms and Conditions. In the event that the Company waives the User’s performance of the obligations under these Terms and Conditions, or relinquishes any rights at any one time, this shall not be deemed to show that the Company has waived or relinquished such rights at any other time.</p>
<p class="p2"><br></p>
<p class="p3"><b>23.<span class="Apple-tab-span">	</span>Governing Law</b></p>
<p class="p7">These Terms and Conditions shall be governed by and construed in accordance with the laws of the Kingdom of Thailand, without reference to any rules relating to conflicts of law.</p>
<p class="p9"><br></p>
<p class="p3"><b>24.<span class="Apple-tab-span">	</span>Arbitration</b></p>
<p class="p7">Any disputes arising out of or in connection with these Terms and Conditions, or any from any communications, transactions or interactions conducted between the Company and the User, or through the use of the Platform, will be settled by arbitration in Bangkok, Thailand, in accordance with the Arbitration Rules of the Thai Arbitration Institute, Ministry of Justice of Thailand, which are in force at this present time; whereby such rules are deemed to be incorporated by reference in these Terms and Conditions. The award of the Arbitrator(s) is final and binding on all parties. <br>
The language of the arbitration will be in English. Arbitration will be conducted by <br>
a single arbitrator, unless otherwise agreed by the parties in writing or as required by the applicable arbitration rules, and the appointment of this arbitrator shall be made by the Thai Arbitration Institute, Ministry of Justice of Thailand.</p>
<p class="p2"><b></b><br></p>
<p class="p3"><b>25.<span class="Apple-tab-span">	</span>Contacting Us</b></p>
<p class="p7">Should you require further information or have any inquiries, please contact us by using the information provided below:</p>
<p class="p12">Kunana Company Limited</p>
<p class="p12">Address: Rasa Tower 1 555 Phahonyothin Chatuchak Bangkok 10900 Thailand<span class="Apple-converted-space"> </span></p>
<p class="p12">E-mail: hello@kuupixmaker.ai</p>
</div>`,
};
